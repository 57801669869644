.directoriesViewMore {
  height: auto;
}

.directory {
  border-radius: 6px;
  border: 1.4px solid #5bafd7;
  background: #5bafd7;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: block;
  width: 100%;
  margin-bottom: 16px;
  padding: 11px 24px;
  cursor: pointer;
}

.directory:last-child {
  margin-bottom: 0;
}

.directoryActive {
  background: #1c1d40;
  color: #ffffff;
}

.directoryHide {
  display: none;
}

.directory:hover {
  background: #1c1d40;
  color: #ffffff;
}

@media screen and (min-width: 768px) {
  .directories {
    flex-direction: row;
  }
}
