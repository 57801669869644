.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 100px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  background: var(--color-gray-150);
  text-align: center;
}

.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 50px;
}

.subtitle {
  margin-bottom: 50px;
  font-weight: 600;
}

.link {
  display: block;
  color: var(--color-white-900);
  background-color: var(--color-blue-400);
  border: 1.4px solid var(--color-blue-400);
  border-radius: 6px;
  padding: 15px;
  line-height: 19px;
  cursor: pointer;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
}

.link:hover,
.link:active {
  color: var(--color-white-900);
  background: #2c2d64;
  border-color: #2c2d64;
}

.check {
  width: 75px;
  height: 75px;
}

@media screen and (min-width: 768px) {
  .wrapper {
    max-width: 650px;
    background: var(--color-white-900);
    box-shadow: var(--box-shadow-300);
    border-radius: 12px;
    margin: 40px auto;
    padding: 150px 90px 25px 90px;
  }

  .title {
    font-size: 32px;
  }

  .subtitle {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
  }
}

@media screen and (min-width: 1320px) {
  .wrapper {
    padding: 50px 100px;
  }
}
