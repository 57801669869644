.checkbox {
  display: inline-block;
  width: auto;
}

.check {
  flex-direction: row;
}

.hidden {
  display: none;
}
