.wrapper {
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 72px);
  max-width: 1240px;
}

.overlay {
  z-index: 6;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
  line-height: 34px;
}

.wrap {
  display: flex;
  position: relative;
}

.content {
  margin-top: 20px;
}

@media screen and (min-width: 768px) {
  .wrapper {
    padding: 20px 15px;
  }
}

@media screen and (min-width: 1008px) {
  .wrapper {
    padding: 20px 40px;
  }

  .sidebar {
    order: 1;
  }

  .content {
    display: flex;
    justify-content: center;
  }

  .rightSide {
    width: 100%;
  }
}

@media screen and (min-width: 1320px) {
  .wrapper {
    padding: 20px 40px 80px;
  }
}
