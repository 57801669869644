.popupHeading {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-gray-250);
}

.notification {
  max-width: 400px;
  width: 100%;
  background: var(--color-white-900);
  position: absolute;
  min-width: 290px;
  right: -79px;
  z-index: 5;
  top: 50px;
  text-align: left;
  outline: none;
  display: block;
  max-height: 450px;
  overflow: auto;
  border: 1px solid var(--color-gray-250);
  border-radius: 8px;
}

.name {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  margin: 0;
}

.subtitle {
  color: var(--color-gray-500);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

.new {
  background-color: var(--color-blue-300);
}

.popupItem {
  padding: 24px;
  border-bottom: 1px solid var(--color-gray-250);
}

.readMark {
  color: var(--color-gray-500);
  padding: 0;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}

.buttonNotification {
  width: 100%;
  background-color: var(--color-white-900);
  color: var(--color-blue-400);
  padding: 16px;
  display: block;
  text-align: center;
}

.buttonNotification:hover,
.buttonNotification:focus {
  text-decoration: underline;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.timeIcon {
  position: absolute;
  left: 25px;
}

.timeText {
  padding-left: 30px;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-black-500);
  font-weight: bold;
  padding-top: 3px;
  margin-bottom: 0;
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  display: block;
  background: rgba(9, 10, 40, 0.08);
  z-index: 4;
  outline: none;
  top: 70px;
}

.relative {
  position: relative;
  max-height: 302px;
  overflow-y: auto;
}

@media screen and (min-width: 768px) {
  .notification {
    min-width: 400px;
    right: 16px;
  }
}
