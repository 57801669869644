.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 32px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  background: var(--color-gray-150);
  min-height: 100vh;
}

.hr {
  margin-bottom: 20px;
}

.link {
  color: var(--color-blue-400);
  display: inline-block;
  text-align: center;
  width: auto;
}

.link:hover {
  color: #2c2d64;
  text-decoration-line: underline;
}

.error {
  color: var(--color-red-400);
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  margin: -20px 0 24px 0;
  line-height: 12px;
}

@media screen and (min-width: 768px) {
  .wrapper {
    min-height: calc(100vh - 80px);
    max-width: 650px;
    background: var(--color-white-900);
    box-shadow: var(--box-shadow-300);
    border-radius: 12px;
    margin: 40px auto;
    padding-left: 90px;
    padding-right: 90px;
  }

  .hr {
    margin-bottom: 40px;
  }

  .error {
    margin: -24px 0 30px 0;
  }
}
