.filter {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.15);
}

.filterTitle {
  color: #1c1d40;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
}

.filterHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filterContent {
  height: calc(100vh - 26px);
  background-color: #fff;
  border-radius: 16px 16px 0 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 20px 0 20px;
  display: flex;
  flex-direction: column;
  min-height: 100px;
}

.filterActions {
  padding: 16px 80px 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  border-top: 1px solid #eaeaea;
  margin: auto -20px 0 -20px;
}

.filterResetAll {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  color: #abafb9;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 80px;
  padding: 12px 8px;
}

.filterApply {
  border: none;
  background-color: #5bafd7;
  border-radius: 6px;
  padding: 12px 8px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-left: auto;
  width: 137px;
}

.filterApply:disabled {
  pointer-events: none;
}

.filterResetAll:disabled {
  pointer-events: none;
}

.filterContainer {
  height: 100%;
  overflow-y: auto;
  margin-top: 32px;
}

.filterClose {
  color: #1c1d40;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: none;
  width: 24px;
  height: 24px;
  border: none;
  padding: 0;
}

.filterClose svg {
  width: 20px;
}

.categoriesTitle {
  color: #1c1d40;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin: 0 0 24px 0;
}

.categoriesSubTitle {
  color: #1c1d40;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 0 0 24px;
}

.checkboxLabel {
  color: #353546;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 24px;
}

.checkboxLabelCapitalize {
  text-transform: capitalize;
}

.checkboxLabel input[type="checkbox"]:checked + i:after {
  background-color: #2c2d64;
}

@media screen and (min-width: 768px) {
  .filter {
    position: absolute;
    max-height: 350px;
    min-height: 350px;
    height: 100%;
    top: 70px;
    background: transparent;
    max-width: 488px;
  }

  .filterContent {
    height: 100%;
    box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.14);
  }

  .filterActions {
    padding: 16px 20px;
  }

  .filterContainer {
    margin-top: 0;
  }

  .filterContentStatus {
    max-width: 280px;
  }

  .filterContentChanges {
    max-width: 280px;
    max-height: 350px;
    min-height: 350px;
  }

  .filterContentOption {
    max-width: 270px;
    transform: translateX(-130px);
    max-height: 250px;
    min-height: 250px;
  }
}

@media screen and (min-width: 1320px) {
  .filterContentOption {
    transform: translateX(0);
  }
}
