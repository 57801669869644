.descText {
  margin: 40px 0;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black-500);
}

.codeInput {
  margin-bottom: 20px;
}

.verifyBtn {
  font-weight: 500;
  margin-bottom: 60px;
}

.verifyEmail {
  font-weight: 500;
  width: 100%;
  margin-bottom: 5px;
}

.content {
  margin-bottom: 60px;
}

.resendBtn {
  color: var(--color-blue-400);
  font-weight: 600;
  text-align: left;
  padding: 0;
  margin-left: 5px;
}

.form {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pointer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: var(--color-gray-500);
}

.popupHeading {
  font-size: 24px;
  line-height: 34px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--color-gray-250);
  font-weight: bold;
  color: var(--color-blue-800);
}

.nextBtn {
  font-weight: 600;
  width: 70%;
  border-radius: unset;
}

.text {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  margin-bottom: 60px;
}

.modal {
  max-width: 485px;
}

.verifyText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1c1d40;
  margin-bottom: 20px;
}

.verifyText span {
  font-weight: 600;
}

.verifyBtnDisabled {
  background: #eaeaea;
  border-color: #eaeaea;
  color: #8b8b8b;
  pointer-events: none;
}

.verifyBtnDisabled:hover {
  background: #abafb9;
  border-color: #abafb9;
}

.verifyEmailDisabled {
  background: #abafb9;
  border-color: #abafb9;
  color: #ffffff;
}

.inputErrorText {
  color: #da4655;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.48px;
  margin: -10px 0 16px 0;
  text-align: left;
}

.inputError {
  background: #fff;
  border: 1px solid #da4655;
}

.disableButton {
  background: #eaeaea;
  border-color: #eaeaea;
  color: #8b8b8b;
  pointer-events: none;
}

.disableButton:hover {
  background: #abafb9;
  border-color: #abafb9;
}
