.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1c1d40;
  display: block;
  margin-bottom: 10px;
}

.answerCount {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  margin-bottom: 15px;
}

.answerDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  display: block;
  margin-bottom: 20px;
}

.linkToPage {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #8b8b8b;
  border: 1.4px solid #eaeaea;
  border-radius: 6px;
  display: block;
  text-align: center;
  padding: 10px;
  width: 100%;
}

.linkToPage:hover {
  color: var(--color-white-900);
  background: var(--color-blue-700);
  border-color: var(--color-blue-700);
}

.box {
  padding: 24px;
  background: var(--color-white-900);
  position: relative;
}

.box:first-child {
  border-radius: 12px 12px 0 0;
}

.box:last-child {
  border-radius: 0 0 12px 12px;
}

.container {
  margin-bottom: 20px;
}

.city {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #8b8b8b;
  position: relative;
  display: flex;
  align-items: center;
}

.dateFlex {
  display: flex;
  justify-content: space-between;
}

.infoIcon {
  display: none;
  padding-right: 10px;
}

.date {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #8b8b8b;
  display: flex;
  align-items: center;
}

.questionQA {
  display: none;
}

.showTablet {
  display: none;
}

@media screen and (min-width: 768px) {
  .infoIcon {
    display: inline-block;
  }

  .container {
    margin-bottom: 0;
    width: 100%;
  }

  .date {
    padding-left: 50px;
  }

  .box {
    max-width: 712px;
    margin: 0 auto;
    padding: 29px 24px 29px 74px;
  }

  .questionQA {
    display: inline-block;
    position: absolute;
    left: 37px;
    top: 35px;
    width: 26px;
  }

  .dateFlex {
    width: 66.66%;
    flex: none;
    justify-content: space-between;
  }

  .answerCount {
    width: 33.33%;
    flex: none;
    margin-bottom: 0;
  }

  .container {
    display: flex;
    align-items: center;
  }

  .showTablet {
    display: block;
  }

  .hideTablet {
    display: none;
  }

  .answerDescription {
    display: none;
  }

  .content {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .title {
    width: 100%;
    margin: 0;
  }

  .linkToPage {
    width: 105px;
  }
}

@media screen and (min-width: 1008px) {
  .box {
    width: 100%;
    max-width: 100%;
  }
}
