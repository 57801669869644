.wrapper {
  margin: 0 auto;
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  max-width: 1204px;
}

.dropdownTitle {
  position: relative;
}

.title,
.subtitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}

.title {
  margin-bottom: 30px;
}

.subtitle {
  margin-bottom: 20px;
}

.dropdownImg {
  position: absolute;
}

.navIcon {
  margin-right: 15px;
  margin-left: 15px;
}

.dropdownClear {
  border: 0;
}

.mb140 {
  margin-bottom: 140px;
}

.dropdownText {
  padding-left: 36px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
}

.logoutLink {
  color: var(--color-red-400);
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  display: block;
  margin-top: 50px;
}

.mr12 {
  margin-right: 12px;
}

.buttonEdit {
  color: var(--color-blue-400);
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  padding: 0;
}

.buttonDelete {
  color: var(--color-red-400);
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  padding: 0;
}

.layoutMain {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: var(--color-gray-500);
}

.creditNumber {
  display: flex;
  color: var(--color-white-900);
}

.numberWrap {
  display: inline-block;
}

.checkWrapperMr {
  margin-right: 50px;
}

.cardsLayout {
  margin: 0 auto;
}

.paymentSubtitle {
  margin-bottom: 24px;
}

.mr15 {
  margin-right: 15px;
}

.cardNumber {
  margin-right: 12px;
}

.cardControl {
  display: flex;
  margin-bottom: 60px;
  justify-content: space-between;
}

.payment_table {
  display: table;
  width: 100%;
  background: var(--color-white-900);
  box-shadow: var(--box-shadow-300);
  border-radius: 12px;
}

.table_cell_first {
  padding-left: 40px;
  display: table-cell;
  vertical-align: middle;
}

.table_cell_last {
  padding-right: 40px;
  display: table-cell;
  vertical-align: middle;
}

.payment_table_wrapper {
  background-color: var(--color-white-900);
}

.tab_icon {
  padding-left: 16px;
  margin-right: 12px;
}

.tabWrapper {
  display: inline-block;
  color: var(--color-black-500);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.tabLink {
  display: flex;
  align-items: center;
  color: var(--color-black-500);
  margin-bottom: 36px;
  white-space: nowrap;
  border: none;
  font-weight: 600;
}

.navInactive {
  border: 3px solid transparent;
}

.navInactive svg {
  fill: var(--color-gray-500);
}

.navActive {
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid var(--color-blue-400);
}

.navActive svg {
  fill: var(--color-blue-400);
  stroke: var(--color-blue-400);
  stroke-width: 0.2;
}

.buttonChangesMargin {
  margin-bottom: 50px;
}

.cardsLayoutItem {
  margin-bottom: 31px;
}

.showDesktop {
  display: none;
}

.sidebar {
  margin: 0;
}

.titleAccount {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #1c1d40;
  display: block;
  margin-bottom: 40px;
}

.mainTitle {
  color: #1c1d40;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 24px;
  text-transform: capitalize;
}

div .team {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .dropdownImg {
    margin-top: 5px;
  }

  .dropdownText {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 0;
  }

  .title,
  .subtitle {
    font-size: 24px;
    line-height: 34px;
  }

  .subtitle {
    margin-bottom: 40px;
  }

  .title {
    margin-bottom: 30px;
  }

  .newCard,
  .editCard {
    width: 100%;
  }

  .buttonSubmitKey {
    width: 100%;
    margin: 19px 0 0 0;
  }

  .cardsLayout {
    margin-bottom: 32px;
  }

  .check_text_mb50 {
    margin-bottom: 50px;
  }

  .paymentMethod {
    background: var(--color-white-900);
    box-shadow: var(--box-shadow-300);
    border-radius: 12px;
    padding: 40px;
    margin: 0 0 60px;
    max-width: 760px;
  }

  .cardControl {
    max-width: 400px;
    margin-bottom: 70px;
  }

  .paymentBold,
  .paymentSubtitle {
    display: table-cell;
    padding: 20px;
    min-width: 100px;
    font-weight: 100;
    border-bottom: 1px solid var(--color-gray-250);
  }

  .cardsLayoutItem:last-child {
    margin-bottom: 0;
  }

  .mainTitle {
    margin-bottom: 32px;
    font-size: 28px;
    line-height: 36px;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    align-items: flex-start;
  }

  div.tabs {
    margin-bottom: 0;
  }

  div.team {
    margin-bottom: 0;
    width: auto;
  }
}

@media screen and (min-width: 1008px) {
  .wrapper {
    padding: 12px 24px;
  }

  .buttonChangesMargin {
    margin-bottom: 27px;
  }

  .title {
    margin-bottom: 30px;
  }

  .hideDesktop {
    display: none;
  }

  .showDesktop {
    display: flex;
    width: 100%;
    margin: 0 auto 0;
  }

  .sidebarData {
    width: 350px;
    margin-right: 55px;
    flex: none;
  }
  .content {
    width: 100%;
    max-width: 905px;
  }

  .sidebar {
    margin-top: 4px;
  }
}

@media screen and (min-width: 1320px) {
  .sidebarData {
    width: 400px;
  }

  .mainTitle {
    margin-bottom: 40px;
  }
}
