.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-white-900);
  background: var(--color-blue-400);
  padding: 8px;
  max-width: 1204px;
  margin: 0 auto;
}

.bannerText {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}

.button {
  background: var(--color-white-900);
  border-radius: 8px;
  color: #1c1d40;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 11px 24px;
}

@media screen and (min-width: 768px) {
  .banner {
    padding: 16px 24px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .textMobile {
    margin-bottom: 0;
  }
}
