.feedback {
  display: flex;
  position: fixed;
  right: -100px;
  top: 20px;
  margin: 20px 0 0 auto;
  color: black;
  font-size: 14px;
  font-weight: 600;
  padding: 20px 50px;
  opacity: 0;
  transition: all 0.3s;
  white-space: nowrap;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.14);
  border-radius: 12px 0 0 12px;
}

.icon {
  margin-right: 20px;
}

.shown {
  opacity: 1;
  background: white;
  right: 0;
  z-index: 4;
}

.close {
  padding: 0 0 0 20px;
}

.closeIcon {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
