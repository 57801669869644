.link {
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #5bafd7;
  margin-top: 15px;
  margin-left: auto;
  text-decoration: underline;
}

.link:hover,
.link:active {
  color: var(--color-blue-700);
  text-decoration-line: underline;
}

@media screen and (min-width: 768px) {
  .link {
    font-size: 16px;
    line-height: 22px;
    margin-top: 23px;
  }
}
