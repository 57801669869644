.popupTitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: var(--color-blue-800);
  margin-top: -17px;
}

.hr {
  margin: 25px 0;
  display: block;
}

.indent {
  margin-top: 70px;
  margin-bottom: 60px;
}

.mB20 {
  margin-bottom: 20px;
}

.textarea {
  min-height: 200px;
  border: 1px solid #d7d2d2;
  border-radius: 6px;
}

.searchButton {
  position: absolute;
  top: 16px;
  left: 16px;
  background-color: transparent;
  border: 0;
}

.selectSearchWrap {
  background: transparent;
}

.search {
  color: var(--color-gray-500);
  margin-bottom: 20px;
}

.btnFooter {
  width: 50%;
}

.select {
  margin-bottom: 20px;
}

.itemOptionBox {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background: var(--color-blue-300);
  border-radius: 4px;
  margin: 15px 12px;
  overflow: hidden;
}

.btnDelete {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 36px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnDelete:hover {
  background: rgba(255, 0, 0, 0.2);
}

.textOption {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black-500);
  margin: 0;
  margin-left: 30px;
}

.questionAnswerBox {
  margin-bottom: 40px;
}

.labelText {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black-500);
  margin-left: 12px;
}

.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.radio {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.question {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-blue-800);
  margin-bottom: 15px;
}

.counter {
  font-size: 24px;
}

.disableSelect {
  background: #eaeaea;
  pointer-events: none;
  border-color: #d7d2d2;
}

.disableSelect div {
  color: #8b8b8b;
  box-shadow: none;
  border-color: #d7d2d2;
}
