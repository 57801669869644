.hr {
  margin: 40px 0;
  display: block;
}

.hr32 {
  margin: 32px 0;
  display: block;
}

.hr20 {
  margin: 20px 0 32px 0;
  display: block;
}

@media screen and (min-width: 768px) {
  .hr {
    display: block;
    width: 100%;
    min-width: 100px;
  }
}
