.call {
  background: var(--color-white-900);
  box-shadow: var(--box-shadow-300);
  border-radius: 12px;
  padding: 20px;
  max-height: 280px;
  min-height: 180px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.col6 {
  width: 50%;
}

.col12 {
  width: 100%;
  display: flex;
}

.label {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.8px;
  color: var(--color-gray-500);
}

.active:hover {
  color: var(--color-blue-700);
  text-decoration-line: underline;
}

.info {
  margin-bottom: 0;
}

.number,
.source,
.date,
.id {
  margin-bottom: 20px;
}

.time {
  margin-bottom: 20px;
}

.duration {
  width: 50%;
}

.wrap {
  width: 50%;
}

.indicator {
  background: var(--color-yellow-100);
  border-radius: 12px;
  padding: 8px 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-green-400);
  letter-spacing: 1.5px;
  text-transform: uppercase;
  display: inline-block;
}

.completed {
  background-color: var(--color-yellow-100);
  color: var(--color-green-400);
}

.noAnswered {
  background-color: var(--color-red-100);
  color: var(--color-red-400);
}

.blocked {
  background-color: var(--color-gray-250);
  color: var(--color-gray-400);
}

.info2 {
  display: none;
}

@media screen and (min-width: 768px) {
  .colSm4 {
    width: 33.3332%;
  }

  .label {
    display: none;
  }

  .call {
    max-height: 139px;
    min-height: 105px;
  }

  .date,
  .id {
    margin-bottom: 0;
  }

  .duration {
    display: none;
    margin-bottom: 20px;
    text-align: right;
  }

  .wrap {
    text-align: right;
    width: 100%;
  }

  .source {
    margin-bottom: 20px;
  }

  .info2 {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .source,
  .id {
    padding-left: 30px;
  }
}
