.modal {
  padding: 24px 20px;
  max-width: 480px;
}

.title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 32px;
}

.label {
  color: #353546;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: block;
  margin-bottom: 8px;
}

.label span {
  color: #da4655;
}

.submit {
  margin-top: 16px;
  padding: 12px;
  border: none;
  line-height: 20px;
  font-weight: 700;
  border-radius: 8px;
}

.formContent {
  margin-bottom: 16px;
  position: relative;
}

.input {
  padding: 11px 12px;
}

.searchInput {
  padding: 11px 12px 11px 40px;
}

.searchContent {
  position: relative;
}

.searchIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -10px;
}

div .calendarInput {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  padding: 11px 10px 11px 40px;
}

.cityStateZipList {
  width: 100%;
  position: absolute;
  top: 100%;
  background-color: transparent;
  padding: 0;
  border: 1px solid #eaeaea;
  max-height: 250px;
  overflow-y: auto;
  z-index: 2;
}

.cityStateZipListItem {
  border: none;
  width: 100%;
  padding: 16px 10px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  gap: 4px;
}

.cityStateZipListItem:hover {
  background-color: #f8f8fa;
}

.cityStateZipListItem:last-child {
  border: none;
}

.cityStateZipListItemCountry {
  color: #61616f;
  font-size: 16px;
  font-weight: 400;
  display: block;
  line-height: normal;
  margin-bottom: 0;
}

.cityStateZipListItemState {
  color: var(--color-gray-500);
  font-size: 14px;
  font-weight: 400;
  display: block;
  line-height: normal;
}

.checkbox {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.checkbox i {
  margin-top: -3px;
}

div.location {
  margin-bottom: 16px;
}

@media screen and (min-width: 768px) {
  .modal {
    padding: 24px;
  }
}
