.card {
  position: relative;
  box-shadow: none;
}

.homePageCard {
  margin-bottom: 0;
  border-radius: 0;
}

.homePageCard:first-child {
  border-radius: 12px 12px 0 0;
}

.homePageCard:last-child {
  border-radius: 0 0 12px 12px;
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.link {
  width: 100%;
  display: block;
  font-weight: 600;
  font-size: 12px;
  color: var(--color-gray-500);
  border: 1.4px solid var(--color-gray-250);
  border-radius: 6px;
  padding: 7px;
  text-align: center;
}

.link:hover,
.link:active {
  color: var(--color-white-900);
  background: var(--color-blue-700);
  border-color: var(--color-blue-700);
}

.name {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin-top: 0;
  text-align: left;
}

.time {
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.text {
  text-align: left;
  margin-bottom: 15px;
  padding-right: 10px;
  white-space: inherit;
  color: #353546;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.partner {
  font-size: 12px;
  line-height: 16px;
  color: var(--color-gray-500);
  font-weight: bold;
  text-align: left;
  margin-bottom: 15px;
  letter-spacing: 0.04em;
}

.homePageFullName {
  font-size: 18px;
  line-height: 26px;
  color: #1c1d40;
}

@media screen and (min-width: 768px) {
  .homePageCard {
    padding: 33px 24px;
  }

  .heading {
    margin-bottom: 12px;
  }

  .text {
    margin-bottom: 0;
  }

  .main {
    flex-direction: row;
  }

  .textWrap {
    width: 80%;
  }

  .more {
    width: 20%;
    display: flex;
    align-items: center;
  }

  .partner {
    padding: 0 15px;
    margin: 0;
  }

  .homePageHeading {
    padding-right: 20%;
  }

  .homePageLink {
    width: 105px;
    margin-left: auto;
  }
}
