.blue,
.red,
.text {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: center;
}

.blue span,
.red span,
.text span {
  display: block;
}

.blue {
  color: #5bafd7;
}

.membershipNameBlue,
.membershipName {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding-left: 4px;
  color: #8b8b8b;
}

.membershipNameActive {
  color: #000000;
}

.membershipNameBlue {
  text-decoration: underline;
  color: #5bafd7;
  display: flex;
  align-items: center;
}

.membershipNameBlue:hover {
  text-decoration: none;
}

.membershipNameBlue svg path {
  fill: #5bafd7;
}

.membershipNameBlue span {
  display: block;
  width: 100%;
  flex: none;
}

.icon {
  margin: 0 0 0 5px;
  flex: none;
  width: 13px;
  height: 13px;
}

.statusContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.statusText {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #8b8b8b;
}

.time {
  margin-top: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #8b8b8b;
}

.status,
.statusRed {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #8b8b8b;
  text-align: left;
}

.statusRed {
  color: #da4655;
}

.red,
.redStatus {
  color: var(--color-red-400);
  text-decoration: none;
}

.statusTexHomePage {
  color: #8b8b8b;
  margin-bottom: 0;
}

.options {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-decoration: none;
  color: #5bafd7;
}

.options:hover {
  text-decoration: underline;
  color: #5bafd7;
}

@media screen and (min-width: 768px) {
  .statusContainer {
    margin-bottom: 16px;
  }
  .statusContainerHome {
    justify-content: flex-start;
  }
}

@media screen and (min-width: 1008px) {
  .blue span,
  .red span,
  .text span {
    display: inline-block;
  }

  .text {
    width: 120px;
    flex: none;
  }

  .membershipName,
  .membershipNameBlue {
    display: flex;
  }

  .membershipName p {
    padding-left: 5px;
  }

  .membershipNameBlue span {
    width: auto;
  }

  .red,
  .redStatus {
    color: var(--color-red-400);
    text-decoration: none;
  }

  .time {
    display: none;
  }

  .redStatus {
    display: block;
  }

  .statusContainer {
    margin: 5px 0 0;
  }
}
