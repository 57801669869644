.wrapper {
  margin: 0 auto;
  padding: 20px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: calc(100vh - 144px);
  background: var(--color-gray-150);
}

.content {
  display: flex;
  width: 100%;
  flex-flow: column wrap;
}

.button {
  position: absolute;
  padding: 0 10px;
  right: 5px;
  top: 40px;
  display: flex;
  align-items: center;
  color: var(--color-gray-400);
}

.button:focus,
.button:hover {
  color: #6d6d6d;
}

.text {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.5px;
  margin-left: 5px;
}

.nextButton {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  display: block;
  color: var(--color-white-900);
  background-color: var(--color-blue-400);
  padding: 16px 15px;
  cursor: pointer;
  margin-bottom: 10px;
}

.nextButton:hover {
  color: var(--color-white-900);
  background: #2c2d64;
  border-color: #2c2d64;
}

.radio {
  display: flex;
  align-items: center;
}

.panel {
  padding-top: 40px;
  margin-bottom: 30px;
}

.textRadio {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  margin: 0 10px;
  color: var(--color-black-500);
}

.tool {
  white-space: normal !important;
}

.footer {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
  background-color: var(--color-white-900);
  border-top: 1px solid var(--color-gray-250);
}

.back {
  color: var(--color-gray-500);
  background: none;
  padding: 15px;
  margin-right: auto;
  border: 0;
}

.skip {
  background-color: transparent;
  color: var(--color-blue-400);
  border: 1.5px solid var(--color-blue-400);
  margin: 0 25px 0 0;
}

.skipDisabled {
  color: var(--color-gray-500);
  border: 1.5px solid var(--color-gray-250);
}

.spinner {
  width: 40px;
  margin-right: 30px;
}

.footerBtn {
  text-align: center;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px;
  text-align: left;
  background: none;
  margin-bottom: 30px;
  position: relative;
}

.input,
.select {
  margin: 0 0 16px 0;
}

.newAccInput {
  margin: 0 0 20px 0;
}

.actionButton {
  cursor: pointer;
  display: block;
  color: var(--color-white-900);
  margin-right: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 4px 16px;
  line-height: 20px;
  border: 0;
  background: none;
  outline: none;
}

.actionButton:last-child {
  margin-right: 0;
}

.actionButton:hover,
.actionButton:focus {
  text-decoration: underline;
  color: white;
}

.actionButtonWrap {
  display: flex;
  align-items: baseline;
}

.date {
  text-transform: uppercase;
}
/*doesn't work*/
.date::placeholder {
  color: var(--color-gray-400);
}

@media screen and (min-width: 768px) {
  .wrapper {
    padding: 40px 20px 80px 20px;
  }

  .footerBtn {
    min-width: 200px;
  }

  .card {
    width: 100%;
    padding: 30px;
    margin: 0 auto 30px auto;
    background-color: var(--color-white-900);
    justify-content: space-around;
    box-shadow: var(--box-shadow-300);
  }

  .content {
    max-width: 536px;
  }

  .input,
  .select {
    margin: 0 0 24px 0;
  }
}

@media screen and (min-width: 1320px) {
  .wrapper {
    padding: 80px 20px 80px 20px;
  }

  .content {
    max-width: 1120px;
  }

  .card {
    padding: 40px;
    margin-right: 0;
    justify-content: flex-start;
  }

  .actionButton {
    margin-right: 0;
  }

  .actionButton:last-child {
    margin-bottom: 0;
  }

  .actionButtonWrap {
    display: block;
  }
}
