.content {
  padding: 16px 12px;
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 24px;
}

.leftSide {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #f5f7ff;
  border-radius: 50%;
  overflow: hidden;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.name {
  color: #1c1d40;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 4px;
}

.time {
  color: #212121;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
}

.time span {
  font-weight: 600;
}

.website {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 12px;
  width: 100%;
  gap: 8px;
  border-radius: 8px;
  background: #5bafd7;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.website:hover {
  color: var(--color-white-900);
  background: var(--color-blue-700);
  border-color: var(--color-blue-700);
}

.website path {
  fill: #fff;
}

.favorites {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.favoritesButton {
  padding: 8px 12px;
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #f8f8fa;
  color: #abafb9;
  border-color: transparent;
}

.favoritesButton svg {
  width: 16px;
}

.iconActive {
  fill: #5bafd7;
}

.iconActive path {
  stroke: #5bafd7;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 32px 24px;
    display: flex;
    justify-content: space-between;
  }

  .contentGallery {
    /*padding: 0;*/
    margin: 0;
    padding-bottom: 0;
  }

  .rightSide {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 24px;
  }

  .favorites {
    margin-top: 0;
    gap: 24px;
  }

  .favoritesButton {
    border-radius: 100%;
    padding: 0;
    flex: none;
    width: 44px;
  }

  .favoritesButton svg {
    width: 24px;
    height: 24px;
  }

  .favoritesButton span {
    display: none;
  }
}
