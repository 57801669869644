.content {
  margin: 60px 0 72px;
  text-align: center;
}

.icon {
  width: 120px;
  margin-bottom: 60px;
}

.message {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-blue-800);
}

.modal {
  max-width: 485px;
}

.form {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.pointer {
  display: flex;
  justify-content: space-between;
  color: var(--color-gray-500);
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
}

.closeIcon {
  display: none;
}

