.wrapper {
  max-width: 1156px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  height: calc(100vh - 140px);
  position: fixed;
  z-index: 3;
}

.wrapperReferral {
  height: calc(100vh - 281px);
}

.leftSide {
  background-color: #fff;
  max-width: 768px;
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.04);
  position: relative;
}

.leftSideDisabled {
  display: none;
}

.content {
  height: 100%;
}

.overlay {
  z-index: 5;
}

.spinnerClassName {
  z-index: 4;
}

.spinner {
  display: none;
}

.selectTeam {
  margin-bottom: 0;
}

.heading {
  margin-bottom: 24px;
}

.title {
  color: #1c1d40;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 16px;
}

@media screen and (min-width: 768px) {
  .leftSide {
    width: 100%;
    max-width: 340px;
    display: block;
  }

  .content {
    display: flex;
    height: calc(100% - 68px);
  }

  .leftSideDisabledTablet {
    display: none;
  }

  .overlayMobile {
    display: none;
  }

  .wrapper {
    position: inherit;
    height: calc(100vh - 80px);
  }

  .wrapperReferral {
    height: calc(100vh - 223px);
  }

  .title {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 0;
  }

  .heading {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

@media screen and (min-width: 1008px) {
  .wrapperReferral {
    height: calc(100vh - 172px);
  }
}

@media screen and (min-width: 1320px) {
  .wrapper {
    padding: 24px 0 0;
    height: calc(100vh - 104px);
  }

  .leftSide {
    display: block;
  }

  .wrapperReferral {
    height: calc(100vh - 196px);
  }
}
