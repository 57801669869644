.case {
  margin: 0 auto;
  color: var(--color-black-500);
  background: var(--color-white-900);
  display: block;
}

.caseWrap {
  padding: 24px;
}

.readMore {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #8b8b8b;
  margin-top: 20px;
  padding: 10px 0;
  border: 1.4px solid #eaeaea;
  border-radius: 6px;
  text-align: center;
}

.case:first-child {
  border-radius: 12px 12px 0 0;
}

.case:last-child {
  border-radius: 0 0 12px 12px;
}

.caseBox {
  margin-bottom: 24px;
  display: block;
  width: 100%;
}

.label {
  color: var(--color-gray-500);
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 4px;
  text-transform: uppercase;
}

.caseBoxContent {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  text-transform: capitalize;
}

.practiceText {
  font-weight: 600;
  margin-right: 20px;
}

.practiceText:last-child {
  margin-top: 18px;
}

.summary {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
}

@media screen and (min-width: 768px) {
  .readMore {
    display: none;
  }

  .case {
    width: 100%;
    display: flex;
  }

  .caseWrap {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: 31px 32px;
  }

  .caseBox {
    padding: 0 20px;
  }

  .label {
    margin-bottom: 15px;
  }

  .caseBoxContent {
    display: flex;
  }

  .practice {
    flex: 5 1 370px;
    padding: 0 20px 0 0;
  }

  .practiceWrp {
    display: flex;
    flex-wrap: wrap;
  }

  .summary,
  .status {
    flex: 1 1 270px;
    padding: 0 20px 0 0;
  }

  .caseBox {
    margin-bottom: 0;
  }
}
