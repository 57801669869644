.checkbox {
  display: block;
  cursor: pointer;
}

.indent .icon,
.indent .icon-radio {
  margin-left: -30px;
}

.checkbox input[type="checkbox"] {
  display: none;
}

.icon {
  height: 20px;
  width: 20px;
  margin-right: 16px;
  display: inline-block;
  border: 1px solid #ddd;
  position: relative;
  bottom: -3px;
  background: var(--color-white-900);
}

.iconBig {
  height: 25px;
  width: 25px;
  margin-right: 16px;
  display: inline-block;
  border: 1px solid #d7d2d2;
  position: relative;
  bottom: -3px;
  border-radius: 4px;
}

.checkbox:hover .icon {
  border-color: var(--color-blue-400);
}

.checkbox input[type="checkbox"]:checked + i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: var(--color-blue-400);
}

.newCheckbox input[type="checkbox"]:checked + i:after {
  background-color: var(--color-blue-700);
  border-radius: 5px;
}

.newCheckbox input[type="checkbox"]:checked + .icon {
  border-color: transparent;
  border: none;
}

.newCheckbox .icon {
  border: 1.4px solid #d7d2d2;
  border-radius: 4px;
}

.checkbox input[type="checkbox"]:checked + i:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-image: url("../../../media/icons/checkbox.svg");
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
}

.checkbox.big input[type="checkbox"]:checked + i:after {
  height: 24px;
  width: 24px;
  border-radius: 4px;
}

.checkbox.big input[type="checkbox"]:checked + i:before {
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-position: center center;
}

.checkbox input[type="checkbox"]:disabled + i {
  border-color: #eee;
}

.checkbox input[type="checkbox"]:disabled:checked + i:after {
  background-color: #999;
}

.checkboxInactive {
  display: block;
  cursor: pointer;
}

.checkboxInactive input[type="checkbox"] {
  display: none;
}

.checkbox:hover .icon {
  border-color: #d7d2d2;
}

.checkboxInactive input[type="checkbox"]:checked + i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: var(--color-gray-250);
}

.checkboxInactive input[type="checkbox"]:checked + i:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-image: url("../../../media/icons/checkbox.svg");
  background-repeat: no-repeat;
  z-index: 1;
  background-position: center center;
}

.checkboxInactive input[type="checkbox"]:disabled + i {
  border-color: #eee;
}

.checkboxInactive input[type="checkbox"]:disabled:checked + i:after {
  background-color: #999;
}

label.disabled {
  cursor: none;
  color: #8b8b8b;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.big.checkboxInactive input[type="checkbox"]:checked + i:after {
  height: 24px;
  width: 24px;
  border-radius: 4px;
}
.checkboxInactive.big input[type="checkbox"]:checked + i:before {
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-position: center center;
}

.big.checkboxInactive .iconBig {
  border: none;
}
