.footer {
  border-top: 14px solid var(--color-blue-800);
  padding: 40px 0 100px;
  background-color: var(--color-white-900);
}

.container {
  max-width: 1220px;
}

.nav {
  text-align: center;
}

.navItem {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #1c1d40;
  display: block;
  margin-bottom: 40px;
}

.navItemButton {
  background-color: transparent;
  border: none;
  outline: none;
  display: block;
  margin: 0 auto 40px;
  cursor: pointer;
}

.info {
  padding: 40px 20px 0;
  border-top: 1px solid var(--color-gray-250);
  text-align: center;
}

.infoTitle {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #1c1d40;
  margin-bottom: 24px;
}

.infoDescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #353546;
  display: block;
  margin-bottom: 24px;
}

.copy {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #353546;
  display: block;
  margin-bottom: 50px;
}

.socials {
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  margin: 0 auto;
  flex-wrap: wrap;
}

.bigLogo {
  display: none;
}

@media screen and (min-width: 768px) {
  .footer {
    padding: 25px 0 40px;
  }

  .content {
    display: flex;
    align-items: center;
    padding-right: 100px;
  }

  .center {
    display: flex;
    width: 100%;
  }

  .bigLogo {
    display: block;
  }

  .nav {
    margin-left: auto;
    text-align: left;
  }

  .info {
    padding: 60px 28px 0;
  }

  .infoFlex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
  }

  .infoFlexBottom {
    display: flex;
    justify-content: space-between;
  }

  .socials {
    width: 220px;
    margin: 0;
    height: auto;
  }

  .copy {
    margin-bottom: 0;
  }

  .infoTitle {
    margin-bottom: 0;
  }

  .infoDescription {
    width: 40%;
    text-align: right;
    margin-bottom: 0;
  }

  .navItemButton {
    margin-left: 0;
    padding: 0;
  }
}

@media screen and (min-width: 1008px) {
  .footer {
    position: relative;
    padding: 25px 0 0;
  }

  .container {
    display: flex;
    margin: 0 auto;
  }

  .info {
    width: 350px;
    text-align: left;
    flex: none;
    border: none;
    margin-left: auto;
  }

  .content {
    width: 100%;
  }

  .infoTitle {
    margin-bottom: 8px;
  }

  .infoFlex,
  .infoFlexBottom {
    display: block;
  }

  .infoDescription {
    width: auto;
    margin: 0 0 35px;
    text-align: left;
  }

  .copy {
    margin-bottom: 30px;
  }

  .socials {
    width: 90px;
    height: 90px;
  }
}
