.modal {
  max-width: 485px;
}

.subTitle {
  color: #1c1d40;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 16px;
}

.checkboxValue {
  color: #353546;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #1c1d40;
  display: block;
  margin-bottom: 24px;
}

.link {
  color: #353546;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px;
}

.linkActive {
  color: #5bafd7;
}

.indent {
  margin: 0 0 18px -12px;
}

.icon {
  width: 16px;
  height: 16px;
  border: 1px solid #abafb9;
}

div .icon:after {
  width: 10px !important;
  height: 10px !important;
}

.select {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #eaeaea;
}

.content {
  width: 254px;
  padding: 16px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.14);
  position: absolute;
  top: 60px;
  right: 10px;
  z-index: 2;
}

.footer {
  padding: 24px 0 0;
  border-top: 1px solid #eaeaea;
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
}

.clear {
  background: transparent;
  border: none;
  padding: 0;
  color: #abafb9;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  flex: none;
  cursor: pointer;
}

.apply {
  width: 100%;
  padding: 14px 24px;
  border-radius: 6px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  background-color: #5bafd7;
  color: #fff;
}
