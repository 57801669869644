.replyBox {
  padding: 60px 0;
}

.textarea {
  height: 150px;
}

.replyDisclaimer {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 40px;
}

.disclaimerText {
  color: var(--color-red-400);
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
}

.replyTitle {
  color: var(--color-gray-500);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

@media screen and (min-width: 1008px) {
  .replyBox {
    max-width: 872px;
    width: 100%;
    margin: auto;
  }
}
