.preloader {
  position: fixed;
  width: 40px;
  height: 40px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.preloaderAbsolute {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
