.content {
  margin: 20px 0 40px 0;
}

.copyLink,
.viewMoreButton {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #5bafd7;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0 0 20px 0;
  display: block;
  position: relative;
  width: auto;
  text-align: left;
  text-decoration: underline;
}

.copyLink:hover,
.viewMoreButton:hover {
  text-decoration: none;
}

.viewMoreButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  padding: 0;
  width: 100%;
}

.copyLink:after {
  content: "";
  width: calc(100% - 110px);
  height: 1px;
  background-color: #eaeaea;
  position: absolute;
  left: 0;
  bottom: 0;
}

.label {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1c1d40;
  display: flex;
  gap: 5px;
}

.copyLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  display: flex;
  gap: 5px;
}

.label span {
  color: #da4655;
}

.directoryContent {
  margin-top: 40px;
}

.directoryContentHide {
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden;
}

.contentTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1c1d40;
  margin-bottom: 40px;
}

.buttonContainerModal {
  margin: 0 -40px;
  gap: 0;
}

button.disabled {
  background-color: #eaeaea;
  border-color: #eaeaea;
  color: #8b8b8b;
  pointer-events: none;
}

.copyListing {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}

.title {
  color: #353546;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 4px;
}

.description {
  color: #8b8b8b;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: block;
  margin-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .contentTitle {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 40px;
  }
}
