.question {
  background: var(--color-white-900);
  box-shadow: var(--box-shadow-300);
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.heading {
  position: relative;
}

.name {
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: var(--color-blue-800);
  margin-bottom: 10px;
}

.selectIcon {
  position: absolute;
  right: 0;
}

.subHeading {
  margin-bottom: 25px;
}

.company {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  width: auto;
  margin-bottom: 0;
}

.city {
  color: var(--color-gray-500);
  position: relative;
  width: auto;
}

.time {
  text-align: right;
  color: var(--color-gray-500);
  position: relative;
  width: auto;
  padding-left: 35px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 16px;
}

.text {
  font-size: 18px;
  line-height: 27px;
  color: var(--color-black-500);
  margin-bottom: 20px;
}

.line {
  width: 100%;
  border-top: 1px solid var(--color-gray-250);
}

.mb25 {
  margin-bottom: 25px;
}

.icon {
  position: absolute;
  left: 0;
}

.subInfo {
  display: flex;
  justify-content: space-between;
  height: 75px;
  align-items: center;
}

.control {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.areas {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: var(--color-black-500);
  margin-right: 16px;
  text-transform: uppercase;
}

.business {
  font-weight: 600;
  font-size: 12px;
  background: var(--color-blue-300);
  border-radius: 6px;
  margin: 0 10px 0 0;
  line-height: 20px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  color: var(--color-blue-400);
  text-transform: uppercase;
}

.cityWrap {
  display: none;
}

.iconFill {
  display: none;
}

.iconButtonFilled svg {
  fill: var(--color-blue-400);
}

.iconButtonFilled:hover .iconFilled {
  display: none;
}

.iconFilled {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url("../../../../media/icons/saved.svg");
}

.iconButton:hover .iconFill {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url("../../../../media/icons/saved.svg");
}

.buttonSave {
  position: absolute;
  right: 0;
}

.iconButtonFilled,
.iconButton {
  padding: 0;
  display: block;
  z-index: 2;
  position: relative;
}

@media screen and (min-width: 768px) {
  .city {
    padding-left: 35px;
  }

  .cityWrap {
    display: flex;
    justify-content: space-around;
  }

  .control {
    justify-content: flex-start;
  }

  .subInfo {
    display: none;
  }

  .buttonSave {
    top: 35px;
  }

  .selectIcon {
    top: 35px;
  }
}

@media screen and (min-width: 1008px) {
  .question {
    max-width: 872px;
    margin: auto;
  }
}
