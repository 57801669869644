.container {
  padding: 20px;
  background-color: #fff;
  margin-bottom: 40px;
  border-radius: 12px;
  position: relative;
  box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.04);
}

.activeContainer {
  z-index: 5;
}

.overlay {
  background: rgba(9, 10, 40, 0.1);
}

.firstStep {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addNewListingButton {
  background: #ac4b77;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  padding: 16px;
  border: none;
  display: block;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  margin-bottom: 12px;
}

.addNewListingButton:hover {
  color: var(--color-white-900);
  background: var(--color-blue-700);
}

.description {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #353546;
  max-width: 300px;
  margin: 0;
}

.tabs {
  border-bottom: 1px solid #eaeaea;
  display: flex;
  gap: 15px;
}

.tabsItem {
  border: none;
  outline: none;
  background-color: transparent;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  padding: 0 12px 15px 12px;
  cursor: pointer;
  color: #8b8b8b;
}

.tabsItem:after {
  content: "";
  display: none;
  height: 3px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #5bafd7;
  border-radius: 5px 5px 0 0;
}

.tabsItemActive {
  color: #1c1d40;
}

.tabsItemActive:after {
  display: block;
}

.closeBtn {
  position: absolute;
  top: -25px;
  right: -25px;
  z-index: 2;
  opacity: 0.85;
  line-height: 1;
  border-radius: 100px;
}

.closeBtn:hover,
.closeBtn:focus {
  opacity: 1;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
}

.formStep {
  position: relative;
}

.content {
  padding: 20px 0 0 0;
  max-width: 540px;
}

.contentTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1c1d40;
  margin-bottom: 20px;
}

.contentDescription {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #353546;
  margin: 0 0 14px 0;
}

.list {
  padding: 0;
  list-style-type: none;
  margin-bottom: 30px;
}

.listItem {
  font-weight: 600;
  font-size: 16px;
  line-height: 34px;
  color: #353546;
  margin-bottom: 10px;
}

.listItem:last-child {
  margin-bottom: 0;
}

.close {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  border: none;
  background: #eaeaea;
  border-radius: 6px;
  display: block;
  width: 100%;
  max-width: 105px;
  padding: 16px 10px;
  cursor: pointer;
}

.submit {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: #5bafd7;
  border-radius: 6px;
  display: block;
  border: none;
  width: 100%;
  padding: 16px 10px;
  cursor: pointer;
  text-align: center;
}

.submit:hover,
.close:hover {
  color: var(--color-white-900);
  background: var(--color-blue-700);
}

.buttonContainer {
  display: flex;
  gap: 14px;
}

.spinner {
  display: none;
}

@media screen and (min-width: 768px) {
  .addNewListingButton {
    margin-bottom: 0;
  }

  .container {
    padding: 35px 40px;
    margin-bottom: 40px;
  }

  .firstStep {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 23px;
    text-align: left;
  }

  .content {
    padding: 40px 0 0 0;
  }

  .contentTitle {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 40px;
  }
}
