.content {
  margin-bottom: 44px;
}

.button {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 13px 12px;
}

.button path {
  stroke: #fff;
}

.button:disabled {
  background: var(--color-gray-250);
  border-color: var(--color-gray-250);
  color: var(--color-gray-500);
  pointer-events: none;
}

.button:disabled path {
  stroke: var(--color-gray-500);
}

@media screen and (min-width: 768px) {
  .content {
    margin-bottom: 48px;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .flex h3 {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1008px) {
  .content {
    margin-bottom: 80px;
  }

  .flex {
    margin-bottom: 48px;
  }
}
