.wrapper {
  max-width: 1440px;
  margin: 0 auto 32px auto;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  background: var(--color-white-900);
  position: relative;
  border-radius: 8px;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.06);
}

.select {
  width: 100%;
  max-width: 100px;
  right: 30px;
  position: absolute;
}

.tooltipD3 {
  background: #353546;
  border-radius: 8px;
  padding: 24px;
  min-width: 160px;
}

.tooltipD3 .linkPanelD3 .tooltipTextD3 {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #ffffff;
}
