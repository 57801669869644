.infoPanel {
  margin-bottom: 40px;
  position: relative;
}

.infoPanel:last-child {
  margin-bottom: 0;
}

.icon {
  position: absolute;
  left: 0;
}

.active:hover {
  color: var(--color-blue-700);
  text-decoration-line: underline;
}

.description {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black-500);
}

@media screen and (min-width: 768px) {
  .userInfo {
    display: flex;
    flex-wrap: wrap;
  }

  .infoPanel {
    width: 50%;
    padding: 0 15px 0 0;
  }
}
