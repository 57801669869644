.flexWrap {
  display: flex;
  flex-wrap: wrap;
}

.inputWrap {
  flex: 1 1 100%;
  margin-bottom: 32px;
}

.textarea {
  height: 104px;
}

.errorText {
  margin-bottom: -10px;
}

.close {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  border: none;
  background: #eaeaea;
  border-radius: 6px;
  display: block;
  width: 100%;
  max-width: 105px;
  padding: 16px 10px;
  cursor: pointer;
}

.submit {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: #5bafd7;
  border-radius: 6px;
  display: block;
  border: none;
  width: 100%;
  padding: 16px 10px;
  cursor: pointer;
}

.submitDisabled {
  pointer-events: none;
  opacity: 0.6;
}

.submit:hover,
.close:hover {
  color: var(--color-white-900);
  background: var(--color-blue-700);
}

.buttonContainer {
  display: flex;
  gap: 14px;
}

@media screen and (min-width: 768px) {
  .flexWrap {
    flex-wrap: nowrap;
    gap: 24px;
  }

  .inputWrap {
    flex: 1 1 50%;
  }
}
