.register {
  display: flex;
  width: 100%;
  flex-flow: column wrap;
  background: var(--color-gray-150);
  padding-bottom: 30px;
  max-width: 536px;
  margin: 0 auto;
}

.line {
  border-bottom: 1px solid var(--color-gray-250);
  padding: 20px 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
}

.register__title {
  margin-bottom: 40px;
}

.register__aside {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
}

.register__aside--welcome {
  align-items: flex-end;
  justify-content: center;
}

.register__caption--welcome {
  max-width: 550px;
  text-align: center;
}

.register__button {
  margin-bottom: 10px;
}

.register__input {
  padding: 15px 20px;
  width: 100%;
  margin: 0 0 20px 0;
}

.register-alternative {
  color: var(--color-gray-500);
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
}
.register__card {
  text-align: left;
  background: none;
  margin-bottom: 30px;
  position: relative;
}

.register__card--step1 {
  padding: 0;
}

.register__card--step1-top {
  padding: 0 0 60px;
  border-radius: 0;
  border-bottom: 1px solid var(--color-gray-250);
}

.register__card--step1-bottom {
  background: none;
  padding: 0;
  text-align: left;
  border: none;
}

.network-checkbox {
  padding: 20px 0;
}

.network-checks__link {
  display: block;
  margin: 25px;
}

.register__radio {
  display: flex;
  flex-direction: column;
}

.register-radio {
  display: flex;
  flex-direction: column;
}

.radio__input {
  margin-right: 5px;
  display: inline-block;
}

.radio__tooltip {
  margin-left: 10px;
}

.register__button--step4 {
  padding: 9px 0;
  margin: 0 0 30px 0;
  width: 100%;
}

.button--upload-picture {
  min-width: 150px;
}

.added-info-card {
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--color-white-900);
  background-color: var(--color-black-500);
  border-radius: 6px;
  padding: 15px 15px 10px 15px;
  width: auto;
}

.added-info-card__remove {
  position: absolute;
  top: 15px;
  right: 5px;
}

.register__hr {
  margin: 30px 0;
}

.register__search {
  padding: 15px 20px;
  min-width: 100%;
  margin: 0 0 30px 0;
}

.register__check {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 20px;
  color: var(--color-black-500);
}

.register__label {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-gray-500);
  display: block;
  white-space: nowrap;
  margin-bottom: 5px;
}

.side-column {
  font-size: 16px;
  font-weight: 600;
  max-width: 300px;
  width: 100%;
  padding: 10px;
  margin: 0 auto;
  text-align: left;
}

.side-column span {
  margin-right: 20px;
  min-width: 60px;
}

.network-bio__textarea {
  min-height: 300px;
  margin: 0 0 10px 0;
}

.textarea-info {
  display: block;
  text-align: center;
}

.textarea-info span:last-child {
  color: var(--color-gray-400);
}

.textarea-info span {
  display: block;
  font-size: 12px;
  font-weight: 700;
  margin: 0 0 15px;
}

.mb0 {
  margin-bottom: 0;
}

.register__card--step6 {
  margin: 0 0 80px;
}

.register__button--step5 {
  width: 100%;
  max-width: 536px;
  margin: 0 auto 30px auto;
  display: block;
}

.register__add-picture {
  position: relative;
  height: 180px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed var(--color-gray-250);
  margin: 0 auto 80px auto;
  background-color: var(--color-white-900);
}

.register__add-picture img {
  max-height: 100%;
  margin: auto;
}

.uploaded-picture__delete {
  all: unset;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.picture {
  font-size: 10px;
  color: var(--color-black-500);
  font-weight: bold;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
  text-align: center;
}

.picture.is-active {
  color: #5bafd7;
}

.picture.is-active .picture__wrp {
  border: 4px solid #5bafd7;
}

.picture__wrp {
  border-radius: 300px;
  border: 4px solid white;
  overflow: hidden;
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.picture img {
  display: block;
}

.tooltip-wrapper {
  display: flex;
  align-items: center;
}

.tooltip-wrapper__img {
  margin: 0 0 8px 10px;
}

.select {
  height: 37px;
}

.social-field {
  display: flex;
  justify-content: center;
}

.social-field__icon {
  margin: 0 0 30px 30px;
}

.register__card--welcome {
  position: relative;
}

.login-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #5bafd7;
  display: block;
}

.register__card--welcome {
  margin-top: 100px;
}

@media screen and (min-width: 768px) {
  .textarea-info span {
    margin: 0;
  }

  .register {
    margin: 30px auto;
    padding: 0;
  }

  .register__card {
    width: 100%;
    padding: 30px;
    margin: 0 auto 30px auto;
    background-color: var(--color-white-900);
  }

  .register__card--step1 {
    border: 1px solid rgba(0, 0, 0, 0.04);
  }

  .register__card--step1-top {
    border-radius: 15px;
    margin-bottom: 50px;
  }

  .register--step4 {
    margin-top: 40px;
  }

  .register__card--welcome {
    margin-top: 100px;
  }

  .register__card--step6 {
    margin: 0 0 50px;
  }

  .added-info-card {
    flex-direction: row;
    align-items: baseline;
    padding: 10px 15px;
  }

  .added-info-card p {
    margin-bottom: 0;
  }

  .added-info-card__year-acquired {
    order: 1;
  }

  .added-info-card__location {
    order: 2;
    padding: 0 10px 0 20px;
    border-right: 1px solid #616162;
    border-left: 1px solid #616162;
    margin: 0 15px;
  }

  .added-info-card__license-number {
    order: 3;
  }

  .added-info-card__remove {
    all: unset;
    order: 4;
    padding: 0;
    margin-left: 10px;
    cursor: pointer;
  }

  .register-disclaimer {
    margin: 30px auto 0 auto;
    max-width: 535px;
    padding: 0;
  }

  .register__card--step7 {
    padding: 0;
    background: none;
    box-shadow: none;
  }

  .register__add-picture {
    height: 280px;
    width: 280px;
    border: 2px dashed var(--color-gray-250);
    margin: 10px auto 40px auto;
    background-color: var(--color-white-900);
  }

  .button--upload-picture {
    margin: 0 auto 40px auto;
  }

  .textarea-info {
    display: flex;
    text-align: left;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .social-field__icon {
    display: none;
  }
}

@media screen and (min-width: 1320px) {
  .register {
    padding: 0 12px;
    max-width: 1120px;
  }

  .register__card {
    padding: 40px;
    margin-right: 0;
    justify-content: flex-start;
  }

  .register__card--welcome {
    margin: 60px auto 65px 25px;
  }

  .register-disclaimer {
    display: none;
  }

  .register-disclaimer--desktop {
    display: block;
    width: 425px;
  }
}
